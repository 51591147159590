import React from 'react'

import { Link } from 'react-router-dom'

import img from '../images/stiill_prasana.png'
import Time from '../components/Time'

import book from '../images/books.png'

import posterOne from '../images/prasanna/mv1.png'
import posterTwo from '../images/prasanna/mv2.png'
import posterThree from '../images/prasanna/mv3.png'
import posterFour from '../images/prasanna/mv4.png'
import posterFive from '../images/prasanna/mv5.png'
import posterSix from '../images/prasanna/mv6.png'
import posterSeven from '../images/prasanna/mv7.png'
import posterEight from '../images/prasanna/mv8.png'
import posterNine from '../images/prasanna/mv9.png'
import posterTen from '../images/prasanna/mv10.png'



import imax from '../images/imax.webp'

import contact from '../images/contact.png'

import two from '../images/4ce.png'
import three from '../images/4dx.png'
import four from '../images/Ice.png'
import one from '../images/pl.png'
import { ScrollLink } from 'react-scroll'


const Home = () => {

    function topPage() {
        window.scroll(0, 0)
    }

    return (

        <>

            <div className='overflow-hidden'>


                <div className=' '>  </div>


                {/* -------------- header section ---------------- */}

                <section className='font-[poppins] bg-gradient-to-r from-gray-200 from-30% via-[#1CB9B9] via-90% to-[#1CB9B9] to-90%  pt-8 md:pt-20  pb-8 md:pb-20 '>

                    <div className=' w-ull px-4 md:w-[80%] mx-auto'>

                        <div className='flex  flex-col md:flex-row items-center gap-y-12 justify-between '>

                            <div className=' w-full md:w-[60%] mx-auto'>

                                <div>
                                    <div className=' mb-6 md:mb-20'>
                                        <h2 className='font-[Bebas Neue] font-bold  text-[20px] md:text-[35px]  mb-2 md:mb-4 '>Modern Trends in Fashion Photography </h2>
                                        <h2 className='font-[Bebas Neue] font-normal text-[18px] md:text-[28px] '>Official Photographer for International Brands, including <span className='font-semibold '> Rolls Royce</span>  </h2>
                                    </div>

                                    <p className='text-[14px] md:text-[18px] font-normal text-gray-700 mb-4 md:mb-6'><span className='font-bold  text-gray-900'>R. Prasanna Venkatesh,</span> An award winning professional photographer,  Contributed articles to Best Photography Today, Classic Imaging, and Studio News Photography magazines. </p>

                                    <div className='flex items-center gap-x-1 md:gap-x-6 '>

                                        <div>
                                            <Link to='https://rzp.io/l/pGE3FiksY' target='_blank'>
                                                {/* <ScrollLink to="contact" smooth={true} duration={500} onClick={topPage}> */}
                                                <p className='bg-[#ff0000] rounded-lg px-4 py-2 md:py-3 font-semibold text-white font-[roboto] text-[12px] md:text-[16px]'>Webinar Fee: ₹199/-</p>
                                                {/* </ScrollLink> */}
                                            </Link>
                                        </div>

                                        <div className='-mt-4'>
                                            <Time />
                                        </div>


                                    </div>

                                </div>

                            </div>

                            <div className=' w-full md:w-[40%] mx-auto flex justify-center'>

                                <div className='w-full  flex justify-center'>
                                    <img src={img} className='object-cover w-full md:w-full ' alt="" />
                                </div>

                            </div>
                        </div>


                    </div>

                </section>


                {/* ---------------------- about us ---------------- */}

                <section className='pt-8 md:pt-20 pb-8 md:pb-20 font-[poppins]'>

                    <div className=' w-full px-4 md:w-[80%] mx-auto '>

                        <div className='flex flex-col md:flex-row items-start  gap-y-8 justify-between'>

                            <div className='w-full  md:w-[50%]'>

                                <div>
                                    <h2 className='font-semibold text-[16px] md:text-[22px]'> Modern Trends in Fashion Photography by Award-Winning Photographer R. Prasanna Venkatesh </h2>

                                    <div>
                                        <ul className='list-disc text-[12px] md:text-[14px] ml-4 md:ml-5 mt-4 md:mt-6 font-[roboto] space-y-2'>
                                            <li>Self-taught professional photographer with <span className='font-semibold'> 23 years</span>  of experience. </li>
                                            <li> Specializes in People and Advertising photography.</li>
                                            <li>  His notable works include Sony India, Phoenix Market City, Velammal Hospitals, and Jeyachandran Gold House.</li>
                                            <li> <span className='font-semibold'>Certified Master</span>  Trainer and Assessor from <span className='font-semibold'>MEPSC</span>  and <span className='font-semibold'>MESC</span> .</li>
                                            <li>Conducted over 400 photography workshops. </li>
                                            <li>Brand ambassador for <span className='font-semibold'>Godox</span>  and former Brand Influencer for <span className='font-semibold'>Sony India</span>. </li>

                                        </ul>
                                    </div>


                                </div>

                            </div>


                            <div className=' w-full md:w-[50%] flex justify-center'>

                                <div className='flex justify-center items-center w-full'>


                                    <div>
                                        <div className='grid grid-cols-5 gap-x-5 gap-y-4 md:gap-y-8 mt-4 '>

                                            <div>
                                                <img src={posterOne} className=' w-20 md:w-24 shadow-md drop-shadow-md rounded-md' alt="" />
                                            </div>
                                            <div>
                                                <img src={posterTwo} className=' w-20 md:w-24 shadow-md drop-shadow-md rounded-md' alt="" />
                                            </div>
                                            <div>
                                                <img src={posterThree} className=' w-20 md:w-24 shadow-md drop-shadow-md rounded-md' alt="" />
                                            </div>
                                            <div>
                                                <img src={posterFour} className=' w-20 md:w-24 shadow-md drop-shadow-md rounded-md' alt="" />
                                            </div>
                                            <div>
                                                <img src={posterFive} className=' w-20 md:w-24 shadow-md drop-shadow-md rounded-md' alt="" />
                                            </div>
                                            <div>
                                                <img src={posterSix} className=' w-20 md:w-24 shadow-md drop-shadow-md rounded-md' alt="" />
                                            </div>
                                            <div>
                                                <img src={posterSeven} className=' w-20 md:w-24 shadow-md drop-shadow-md rounded-md' alt="" />
                                            </div>
                                            <div>
                                                <img src={posterEight} className=' w-20 md:w-24 shadow-md drop-shadow-md rounded-md' alt="" />
                                            </div>
                                            <div>
                                                <img src={posterNine} className=' w-20 md:w-24 shadow-md drop-shadow-md rounded-md' alt="" />
                                            </div>
                                            <div>
                                                <img src={posterTen} className=' w-20 md:w-24 shadow-md drop-shadow-md rounded-md' alt="" />
                                            </div>

                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>
                </section>


                {/* ------------------- form   ------------------------ */}

                <section className=' mb-10' id='contact'>
                    <div className='w-full mx-auto font-[poppins]'>

                        <div className='flex flex-col-reverse md:flex-row items-center'>

                            <div className='w-full md:w-[45%] bg-blue-600 md:pt-2 md:pb-2 md:pr-2' >

                                <img src={contact} className='w-full' alt="" />

                            </div>

                            <div className=' w-full md:w-[55%] bg-gray-950 '>

                                <div class=" pt-10 pb-10  inset-0 -z-10 h-full w-full items-center px-5 py-24 [background:radial-gradient(125%_125%_at_50%_10%,#000_40%,#63e_100%)]">

                                    <div>
                                        <div class="flex flex-col items-center justify-center  md:px-40  md:py-4 ">

                                            <div>
                                                <h3 className='text-white mb-4 md:mb-10 text-[18px] md:text-[30px] uppercase font-bold'>Join Our Webinar </h3>
                                            </div>

                                            <div class="mx-auto w-full ">

                                                <div className='my-4 md:my-28'>
                                                    <h3 className='text-[16px] md:text-[28px] text-center font-semibold text-white '>"Join our still photography webinar now! Capture groundbreaking insights and elevate your craft today."</h3>
                                                    <p className='text-[12px] md:text-[18px] text-gray-100 text-center mt-8 md:mt-20'>Stay ahead in fashion photography with insights on visual storytelling, technical mastery, and the latest aesthetic trends.</p>
                                                </div>

                                                {/* <div class="mb-2 md:mb-5">
                                                    <label for="name" class="mb-3 block  font-medium text-white text-[12px] md:text-[14px]" >
                                                        Full Name
                                                    </label>
                                                    <input type="text" name="name" id="name" placeholder="Full Name"
                                                        class="w-full rounded-md border border-[#e0e0e0] bg-white text-[12px] md:text-[14px] py-2 md:py-3 px-3 md:px-6 font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md" />
                                                </div>
                                                <div class="mb-2 md:mb-5">
                                                    <label for="email" class="mb-3 block  font-medium text-white text-[12px] md:text-[14px]">
                                                        Email Address
                                                    </label>
                                                    <input type="email" name="email" id="email" placeholder="Enter your email"
                                                        class="w-full rounded-md border border-[#e0e0e0] bg-white text-[12px] md:text-[14px] py-2 md:py-3 px-3 md:px-6 font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md" />
                                                </div>
                                                <div class="mb-2 md:mb-5">
                                                    <label for="phone" class="mb-3 block  font-medium text-white text-[12px] md:text-[14px]">
                                                        Phone Number
                                                    </label>
                                                    <input type="text" name="phone" id="phone" placeholder="Enter your phone number"
                                                        class="w-full rounded-md border border-[#e0e0e0] bg-white text-[12px] md:text-[14px] py-2 md:py-3 px-3 md:px-6 font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md" />
                                                </div>
                                                <div class="mb-2 md:mb-5">
                                                    <label for="phone" class="mb-3 block  font-medium text-white text-[12px] md:text-[14px]">
                                                        Whatsapp Number
                                                    </label>
                                                    <input type="text" name="phone" id="phone" placeholder="Enter your Whatsapp Number"
                                                        class="w-full rounded-md border border-[#e0e0e0] bg-white text-[12px] md:text-[14px] py-2 md:py-3 px-3 md:px-6 font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md" />
                                                </div>



                                                <div class="mb-2 md:mb-5 ">
                                                    <label class="mb-3  block  font-semibold text-white  text-[12px] md:text-[14px]">
                                                        Location
                                                    </label>

                                                    <input type="text" name="phone" id="phone" placeholder="Enter your Location"
                                                        class="w-full rounded-md border border-[#e0e0e0] bg-white text-[12px] md:text-[14px] py-2 md:py-3 px-3 md:px-6 font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md" />

                                                </div> */}

                                                <div>
                                                    <Link to='https://rzp.io/l/pGE3FiksY' target='_blank'>
                                                        <button
                                                            class="text-[14px] md:text-[16px] flex items-center gap-x-1 md:gap-x-3 justify-center border border-white font-[roboto] hover:shadow-form w-full rounded-md bg-[#ff0000] py-2 md:py-3 px-4 md:px-8 text-center  font-semibold text-white outline-none">
                                                            Pay Now <span className='font-bold text-[16px] md:text-[18px]'>₹199 + GST</span>
                                                        </button>
                                                    </Link>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div >

                </section >




                {/* imax screen */}

                {/* <section className='pt-4 md:pt-20 pb-8  md:pb-20 font-[poppins]'>
                    <div className=' w-full px-4 md:w-[80%] mx-auto'>

                        <div className='flex flex-col-reverse md:flex-row  items-center justify-center gap-y-8 gap-x-28'>



                            <div className=' w-full md:w-[50%] mx-auto'>
                                <div className='flex justify-center items-center'>
                                    <div>
                                        <img src={imax} className='w-full rounded-md shadow-md drop-shadow-md' alt="" />
                                    </div>
                                </div>
                            </div>


                            <div className='w-full md:w-[50%]'>

                                <div>
                                    <h3 className='font-semibold text-[20px] md:text-[28px] mb-4 md:mb-8'>Why learn IMAX?</h3>
                                    <p className=' text-[12px] md:text-[14px] font-[roboto] text-gray-700 mb-1 md:mb-2'> The IMAX cinema process increases the image resolution by using a larger film frame creating a powerful and immersive audio experiences. </p>
                                    <p className=' text-[12px] md:text-[14px] font-[roboto] text-gray-700'> Are you also interested to create a masterpiece like this?</p>
                                </div>



                            </div>

                        </div>

                    </div>
                </section> */}


                {/* ------------------------------ course deatils ------------------- */}

                <section>
                    <div className='w-full px-4 md:w-[80%] mx-auto pt-1 md:pt-20 pb-8 md:pb-20 font-[poppins]'>

                        <div className='flex justify-center items-center mb-6 md:mb-0'>
                            <h3 className='text-center font-bold text-[18px] md:text-[30px] uppercase'>Topics to Be Covered</h3>
                        </div>

                        <div className='flex flex-col md:flex-row items-center mt-2 md:mt-0 gap-y-6 md:gap-y-10'>

                            <div className='w-full md:w-[50%] flex flex-col justify-end md:pr-10 md:border-r border-gray-900'>

                                <h3 className='font-semibold text-[16px] md:text-[20px] mb-3 text-start md:text-end'> Introduction</h3>
                                <ul className='flex flex-col gap-y-2 list-disc md:list-none ml-3 md:ml-0 '>
                                    <li className='text-[12px] md:text-[14px] text-gray-700  text-start font-[roboto] md:text-end'>Brief overview of R. Prasanna Venkatesh's career and achievements</li>
                                    <li className='text-[12px] md:text-[14px] text-gray-700  text-start font-[roboto] md:text-end'>Introduction to the topic and the importance of staying updated with modern trends in fashion photography</li>
                                </ul>

                            </div>

                            <div className=' w-full md:w-[50%]  md:mt-48 md:pl-10 md:border-l border-gray-900'>

                                <h3 className='font-semibold text-[16px] md:text-[20px] mb-3'> Aesthetic Trends in Fashion Photography</h3>
                                <ul className='flex flex-col gap-y-2 list-disc md:list-none ml-3 md:ml-0'>
                                    <li className='text-[12px] md:text-[14px] text-gray-700  text-start font-[roboto] md:text-start'>Visual Storytelling: The rise of narrative-driven fashion shoots</li>
                                    <li className='text-[12px] md:text-[14px] text-gray-700  text-start font-[roboto] md:text-start'>Minimalism vs. Maximalism: Current preferences and how to balance both</li>
                                    <li className='text-[12px] md:text-[14px] text-gray-700  text-start font-[roboto] md:text-start'>Color Trends: Popular color palettes and their impact on fashion imagery</li>
                                    <li className='text-[12px] md:text-[14px] text-gray-700  text-start font-[roboto] md:text-start'>Lighting Techniques: How natural and artificial lighting are used to create different moods</li>
                                </ul>
                            </div>

                        </div>

                        <div className='flex flex-col md:flex-row items-center mt-4 md:mt-0 gap-y-6 md:gap-y-0'>

                            <div className='w-full md:w-[50%] flex flex-col justify-end md:pr-10 md:border-r border-gray-900'>

                                <h3 className='font-semibold text-[16px] md:text-[20px] mb-3 text-start md:text-end'>  Technical Aspects</h3>
                                <ul className='flex flex-col gap-y-2 list-disc md:list-none ml-3 md:ml-0'>
                                    <li className='text-[12px] md:text-[14px] text-gray-700  text-start font-[roboto] md:text-end'>Camera and Lens Choices: Recommendations for fashion photography</li>
                                    <li className='text-[12px] md:text-[14px] text-gray-700  text-start font-[roboto] md:text-end'>Post-Processing: Techniques for enhancing photos while maintaining a natural look</li>
                                    <li className='text-[12px] md:text-[14px] text-gray-700  text-start font-[roboto] md:text-end'>Use of Technology: Incorporating AI and advanced software in fashion photography</li>
                                    <li className='text-[12px] md:text-[14px] text-gray-700  text-start font-[roboto] md:text-end'>Focus and Composition: Tips for achieving sharp and well-composed images</li>
                                </ul>
                            </div>


                            <div className='w-full md:w-[50%]  md:mt-48 md:pl-10 md:border-l border-gray-900'>

                                <h3 className='font-semibold text-[16px] md:text-[20px] mb-3'> Trends in Makeup, Color Schemes, Props, and Artist-Photographer Communication</h3>
                                <ul className='flex flex-col gap-y-2 list-disc md:list-none ml-3 md:ml-0'>
                                    <li className='text-[12px] md:text-[14px] text-gray-700  text-start font-[roboto] md:text-start'>Makeup Trends: Current makeup styles and their photographic implications</li>
                                    <li className='text-[12px] md:text-[14px] text-gray-700  text-start font-[roboto] md:text-start'>Color Schemes: How different color schemes can affect the overall mood and appeal of fashion photos</li>
                                    <li className='text-[12px] md:text-[14px] text-gray-700  text-start font-[roboto] md:text-start'>Props: The use of props to enhance the storytelling and visual interest of fashion shoots</li>
                                    <li className='text-[12px] md:text-[14px] text-gray-700  text-start font-[roboto] md:text-start'>Artist and Photographer Communication: Effective communication strategies for achieving the desired outcome in fashion photography</li>

                                </ul>
                            </div>

                        </div>



                    </div>

                </section >



                <section className='pt-8 md:pt-20 pb-8 md:pb-20 bg-gray-950  font-[poppins]'>
                    <div className=' w-full px-4 md:w-[80%] mx-auto'>

                        <div className='flex flex-col gap-y-2 md:gap-y-5 justify-center items-center'>

                            <h3 className=' text-[16px] md:text-[30px]  font-semibold text-[#ff0000] text-center'>Join The Webinar</h3>
                            <p className='font-[roboto] text-[12px]  md:text-[16px] text-gray-200 text-justify md:text-center '>Join the webinar to discover the latest trends and techniques in fashion photography from award-winning photographer R. Prasanna Venkatesh. Elevate your craft with expert insights on visual storytelling, lighting, and modern aesthetic trends</p>

                            <div className='mt-2 md:mt-5'>
                                <Link to='https://rzp.io/l/pGE3FiksY' target='_blank'>

                                    <button className='bg-[#ff0000] hover:scale-105 duration-300 border-2 border-white px-5 md:px-6 py-1.5 md:py-2.5 rounded-lg text-[12px] md:text-[14px] uppercase font-semibold flex items-center gap-x-2 text-white font-[Bebas Neue] md:tracking-[1px]'> Apply Now <span></span></button>

                                </Link>
                            </div>
                        </div>

                        {/* <div className='flex justify-center mt-14'>

                            <div className='grid grid-cols-2 md:grid-cols-4 gap-y-8 gap-x-6 md:gap-x-14'>

                                <div>
                                    <div className='flex justify-center items-center mb-3'>
                                        <img src={one} className=' object-cover w-16 md:w-24' alt="" />
                                    </div>
                                    <div className='flex flex-col gap-y-2'>
                                        <h3 className='text-white text-center text-[12px]'>PLF </h3>
                                        <p className=' text-[10px] md:text-[12px] text-gray-100 text-center'>Enhanced viewing experience with larger screens and superior sound quality</p>
                                    </div>
                                </div>
                                <div>
                                    <div className='flex justify-center items-center mb-3'>
                                        <img src={two} className=' object-cover w-16 md:w-24' alt="" />
                                    </div>
                                    <div className='flex flex-col gap-y-2'>
                                        <h3 className='text-white text-center'>EPIQ</h3>
                                        <p className=' text-[10px] md:text-[12px] text-gray-100 text-center'>Cutting-edge format offering immersive visuals and sound</p>
                                    </div>
                                </div>
                                <div>
                                    <div className='flex justify-center items-center mb-3'>
                                        <img src={three} className=' object-cover w-16 md:w-24' alt="" />
                                    </div>
                                    <div className='flex flex-col gap-y-2'>
                                        <h3 className='text-white text-center'>4DX</h3>
                                        <p className=' text-[10px] md:text-[12px] text-gray-100 text-center'> Multi-sensory cinema experience with motion seats and environmental effects</p>
                                    </div>
                                </div>
                                <div>
                                    <div className='flex justify-center items-center mb-3'>
                                        <img src={four} className=' object-cover w-16 md:w-24' alt="" />
                                    </div>
                                    <div className='flex flex-col gap-y-2'>
                                        <h3 className='text-white text-center'>ICE </h3>
                                        <p className=' text-[10px] md:text-[12px] text-gray-100 text-center'>Advanced visual and sound technology for a heightened cinematic experience</p>
                                    </div>
                                </div>

                            </div>

                        </div> */}

                    </div>
                </section>

            </div >

        </>

    )
}

export default Home